import React from "react";
import PropTypes from "prop-types";
import 'core-js/stable';
import fetch from 'isomorphic-fetch';
import {evalCalculation, isRelevant} from './lib/EvalExpressHelper';
import {getGroupEndIndex, isEmpty, cleanRecord, cleanRepeatersRecord, isVisible, CreateFormData} from './lib/SurveyHelper';
import GroupLabel from './GroupLabel';
import Language from './Language';
import Spinner from './Spinner';
import Field from './Field';
import ReCAPTCHA from "react-google-recaptcha";
import SinglePageRepeater from "./SinglePageRepeater"
import { v4 as uuidv4 } from 'uuid';

var CACHE_STATE_NAME = "SurveyFormCache";

// var recaptchaRef = React.createRef();


function initialRecord(form, currentUser, autofillParams, survey){
  var record = {
  };
  form.fields.forEach(function(field, i){
    if(field.metadata){
      switch(field.name) {
        case "email":
          record['email'] = currentUser? currentUser.email: null;
          break;
      }
    }
    if(survey.allow_autofill == true) {
      Object.keys(autofillParams).forEach(function (key) {
        if(field.name == key){
          record[field.name] = autofillParams[key]
        }
      });
    };
  });
  return record;
} 

function initialState(props){
  let cacheID = `${CACHE_STATE_NAME}_${props.survey.id}_${props.currentUser.id}`
  let cacheState = localStorage.getItem(cacheID)
  if(cacheState){
    let state = JSON.parse(cacheState)
    if (props.survey.updated_at == state.cacheID.surveyUpdatedAt){
      return {
        form: props.form,
        files: {},
        survey: props.survey,
        submissionUrl: props.submission_url,
        currentUser: props.currentUser,
        record: state.record,
        repeatersRecord: state.repeatersRecord,
        languageIndex: state.languageIndex,
        formSubmitting: false,
        formSubmitted: false,
        submissionAuthFailed: null,
        serverError: null,
        loaded: false,
        gettingLocation: false,
        autofillParams: props.autofillParams,
        loadFromCache: true,
        captchaSiteKey: props.captchaSiteKey
      };
    }
  }
  
  localStorage.removeItem(cacheID);
  
  let languageIndex = 0;
  if(props.form.default_language)
  {
    languageIndex = props.form.languages.indexOf(props.form.default_language);
    languageIndex = languageIndex < 0 ? 0: languageIndex;
  }

  return {
    form: props.form,
    survey: props.survey,
    submissionUrl: props.submission_url,
    currentUser: props.currentUser,
    record: initialRecord(props.form, props.currentUser, props.autofillParams, props.survey),
    files: {},
    repeatersRecord: {},
    languageIndex: languageIndex,
    formSubmitting: false,
    formSubmitted: false,
    submissionAuthFailed: null,
    serverError: null,
    loaded: false,
    gettingLocation: false,
    autofillParams: props.autofillParams,
    loadFromCache: false,
    captchaSiteKey: props.captchaSiteKey
  };
}

class SurveyForm extends React.Component {

  constructor(props){
    super(props);
    this.state = initialState(props);
    
    this.setLanguage = this.setLanguage.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleMultiSelectChange = this.handleMultiSelectChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleGroupedCheckboxes = this.handleGroupedCheckboxes.bind(this);
    this.getLocation = this.getLocation.bind(this);
    this.warnBeforeLeaving = this.warnBeforeLeaving.bind(this);
    this.refreshPage = this.refreshPage.bind(this);
    this.closePage = this.closePage.bind(this);
    this.handleCalculation = this.handleCalculation.bind(this);
    this.handleRepeater = this.handleRepeater.bind(this);
    this.addIntervalCaching = this.addIntervalCaching.bind(this);
    this.removeIntervalCaching = this.removeIntervalCaching.bind(this);
    this.handleCaching = this.handleCaching.bind(this);
    this.loadFromCache = this.loadFromCache.bind(this);
    this.executeSubmit = this.executeSubmit.bind(this);
    this.updateRepeaterRecords = this.updateRepeaterRecords.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.updateFiles = this.updateFiles.bind(this);
    this.handleRemoveValue = this.handleRemoveValue.bind(this);
  }

  componentWillMount(){
    this.addCacheListener();
    this.setState({loaded:true});
  }

  addCacheListener(){
    if(!this.state.loadFromCache){
      this.addIntervalCaching();
      window.addEventListener("focus", this.addIntervalCaching);
      window.addEventListener("blur", this.removeIntervalCaching);
    }
  }

  addIntervalCaching(){
    if(!this.intervalId){
      this.intervalId = setInterval(this.handleCaching, 1000);
    }
  }

  removeIntervalCaching(){
    clearInterval(this.intervalId);
    this.intervalId = null;
  }

  handleCaching(){
    let record = this.state.record;
    let languageIndex = this.state.languageIndex;
    let repeatersRecord = this.state.repeatersRecord;
    if (record || languageIndex || repeatersRecord){
      let cacheState = {
        record: {...record}, 
        languageIndex: languageIndex, 
        repeatersRecord: {...repeatersRecord},
        cacheID:{
          surveyID: this.state.survey.id, 
          surveyUpdatedAt: this.state.survey.updated_at,
          currentUserID: this.state.currentUser.id
        }
      }
      localStorage.setItem(`${CACHE_STATE_NAME}_${this.state.survey.id}_${this.state.currentUser.id}`, JSON.stringify(cacheState))
    }
  }

  removeCaching(){
    localStorage.removeItem(`${CACHE_STATE_NAME}_${this.state.survey.id}_${this.state.currentUser.id}`);
  }

  loadFromCache(){
    this.setState({loadFromCache:false}, this.addCacheListener);
  }

  warnBeforeLeaving(){
    return "This survey is not complete. You will lose your data if you do not submit this form."
  }

  setLanguage(event){
    this.setState({
      languageIndex: event.target.value
    })
  }

  getLocation(event){
    this.setState({
      gettingLocation: true
    })
    let output = event.target.parentElement.parentElement.lastChild;
    let newRecord = this.state.record;
    let name = event.target.name;
    if (!navigator.geolocation){
      output.value = "Geolocation is not supported by your browser";
      return;
    }
    let success = (position) => {
      newRecord[name + "_lat"] = position.coords.latitude
      newRecord[name + "_lng"] = position.coords.longitude
      newRecord[name + "_alt"] = position.coords.altitude
      newRecord[name + "_acc"] = position.coords.accuracy

      this.setState({
        gettingLocation: false,
        record: newRecord
      })
    }

    let error = () => {
      output.value = "Unable to retrieve your location";
      this.setState({
        gettingLocation: false
      })
    }
    navigator.geolocation.getCurrentPosition(success, error);
  }

  handleFileChange(event) {
    let recordCopy = {...this.state.record};
    let files = {...this.state.files};
    if (event.target.files.length > 0){
      let extensionName = event.target.files[0].name.split(".").pop();
      let filename = `${Date.now()}.${extensionName}`;
      
      files[filename] = event.target.files[0];
      recordCopy[event.target.name] = filename;
    }
    else{
      recordCopy[event.target.name] = "";
    }

    
    let relevantRecord = this.relevantRecord(recordCopy);
    relevantRecord = this.handleCalculation(relevantRecord);
    let repeaterRecord = this.handleRepeater(relevantRecord)
    this.setState({
      record: relevantRecord,
      repeatersRecord: repeaterRecord,
      files: files
    });
  }

  updateFiles(files){
    this.setState({
      files: files
    });
  }

  handleChange(event) {
    let recordCopy = {...this.state.record};
    recordCopy[event.target.name] = event.target.value;
    let relevantRecord = this.relevantRecord(recordCopy);
    relevantRecord = this.handleCalculation(relevantRecord);
    let repeaterRecord = this.handleRepeater(relevantRecord)
    this.setState({
      record: relevantRecord,
      repeatersRecord: repeaterRecord
    });
  }

  handleMultiSelectChange(event){
    var options = event.target.options;
    let name = event.target.name;
    var value = [];
    for (var i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    let newRecord = {...this.state.record};
    newRecord[name] = value;
    let relevantRecord = this.relevantRecord(newRecord)
    relevantRecord = this.handleCalculation(relevantRecord)
    let repeaterRecord = this.handleRepeater(relevantRecord)
    this.setState({
      record: relevantRecord,
      repeatersRecord: repeaterRecord
    });
  }

  handleGroupedCheckboxes(field, option, options, event){
    let name = event.target.name;
    let value = event.target.value
    let newRecord = this.state.record;
    if(typeof newRecord[name] === 'undefined'){
      newRecord[name] = []
    }
    else{
      if(option.filter){
        // check if existing checked values should be filtered
        for (let i = 0; i < newRecord[name].length; i++){
          for (let x = 0; x < options.length; x++){
            if (newRecord[name][i] === options[x].name){
              if (field.group_name){
                let groupFilter = field.group_name + "_" + options[x].filter.field;
                if(newRecord[groupFilter] !== options[x].filter.value){
                  let index = newRecord[name].indexOf(newRecord[name][i]);
                  if (index > -1) {
                    newRecord[name].splice(index, 1);
                  }
                }
              }
              else if(newRecord[options[x].filter.field] !== options[x].filter.value){
                let index = newRecord[name].indexOf(newRecord[name][i]);
                if (index > -1) {
                  newRecord[name].splice(index, 1);
                }
              }
            }
          }
        }
      }
    }
    if(newRecord[name].includes(value)){
      let index = newRecord[name].indexOf(value);
      if (index > -1) {
        newRecord[name].splice(index, 1);
      }
    }else{
      newRecord[name].push(value)
    }

    let relevantRecord = this.relevantRecord(newRecord)
    relevantRecord = this.handleCalculation(relevantRecord)
    let repeaterRecord = this.handleRepeater(relevantRecord)
    this.setState({
      record: relevantRecord,
      repeatersRecord: repeaterRecord
    });
  }

  updateRepeaterRecords(repeater, records){
    let repeatersRecord = {...this.state.repeatersRecord};
    repeatersRecord[repeater.name] = records;
    this.setState({repeatersRecord: repeatersRecord});
  }

  handleCalculation(recordCopy){
    let fields = this.props.form.fields;
    for(let cnt=0; cnt < fields.length; cnt++){
      let field = fields[cnt];
      if(field.type == "group" && !field.is_repeater){
        cnt = getGroupEndIndex(fields, cnt, field.name);
        continue;
      }
      else if(!isRelevant(recordCopy, field)){
        if (field.type == "group"){
          cnt = getGroupEndIndex(fields, cnt, field.name);
        }
        continue;
      }

      let ismeta = field.name == "meta_instance_id"
      if(["group", "end_group"].includes(field.type) == false && ismeta == false) {
        if (field.calculate){
          let val = evalCalculation(recordCopy, field.calculate)
          if(val != recordCopy[field.name]){
            recordCopy[field.name] = val
          }
        }
      }
    }
    return recordCopy;
  }

  handleRepeater(recordCopy){
    let repeaterRecord = {...this.state.repeatersRecord};
    let fields = this.props.form.fields.filter(e=> e.is_repeater);
    for(let cnt=0; cnt < fields.length; cnt++){
      let field = fields[cnt];
      let repeaterCount = field.repeater_count;
      if(repeaterCount){
        let rRecords = repeaterRecord[field.name];
        if(!rRecords){ rRecords = [] }
  
        let rowCount = 0;
        let rptCountVal = recordCopy[repeaterCount.field];
        if(!rptCountVal){
          repeaterRecord[field.name] = [];
          continue;
        }
  
        rowCount = parseInt(recordCopy[repeaterCount.field]);
        if(!rowCount || rowCount == 0){
          repeaterRecord[field.name] = [];
          continue;
        }
  
        let rRecordsLength = rRecords.length;
        if(rRecordsLength < rowCount){
          for(let cnt =0; cnt < rowCount - rRecordsLength; cnt++){
            rRecords.push({id: uuidv4()});
          }
          repeaterRecord[field.name] = rRecords
        }
        else if(rRecordsLength > rowCount){
          rRecords = rRecords.slice(0, rowCount)
          repeaterRecord[field.name] = rRecords
        }
      }
    }
    return repeaterRecord;
  }

  handleRemoveValue(e){
    let recordCopy = {...this.state.record};
    let files = {...this.props.files};
    delete files[recordCopy[e.target.value]];
    delete recordCopy[e.target.value];
    
    let relevantRecord = this.relevantRecord(recordCopy)
    relevantRecord = this.handleCalculation(relevantRecord)
    this.setState({
      record: relevantRecord
    });

    this.updateFiles(files);
  }

  relevantRecord(recordCopy){
    let record = {};
    let fields = this.props.form.fields;
    for(let cnt=0; cnt < fields.length; cnt++){
      let field = fields[cnt];
  
      if(field.type == "group" && field.is_repeater){
        cnt = getGroupEndIndex(fields, cnt, field.name);
        continue;
      }
      else if(!isRelevant(record, field)){
        if (field.type == "group"){
          cnt = getGroupEndIndex(fields, cnt, field.name);
        }
        continue;
      }
  
      let excludeType = ["group", "end_group"];
      let includedType = (field.type != "string") || (field.type == "string" && (field.name !== "meta_instance_id" || !field.readonly || !field.metadata)) 
      if(includedType && excludeType.includes(field.type) === false){
        record[field.name] = recordCopy[field.name];
      }

      if(field.type == "barcode"){
        record[`${field.name}_confirmation`] = recordCopy[`${field.name}_confirmation`];
      }

      if(field.type === "geopoint"){
        record[field.name + "_lat"] = recordCopy[field.name + "_lat"];
        record[field.name + "_lng"] = recordCopy[field.name + "_lng"];
        record[field.name + "_alt"] = recordCopy[field.name + "_alt"];
        record[field.name + "_acc"] = recordCopy[field.name + "_acc"];
      }
    }

    return record;
  }

  async handleSubmit(event){
    event.preventDefault();
    this.setState({formSubmitting: true});
    let promisedSetState = (newState) => new Promise(resolve => this.setState(newState, resolve));
    await promisedSetState({formSubmitting: true});

    let validateResult = this.validateForm();
    let valid = validateResult == null;
    if(valid){
      let that = this;
      try{
          that.executeSubmit("")
//           recaptchaRef.current.executeAsync().then(response=>{
//           if(response){
//             that.executeSubmit(response)
//           }
//           else{
//             that.setState({formSubmitting: false, submissionAuthFailed: true});
//           }
//         });

      }catch(e){
        console.log(e);
        that.setState({formSubmitting: false, submissionAuthFailed: true});
      }
    }
    else{
      let field = this.state.form.fields.filter(e=> e.name === validateResult.fieldName)[0];
      let label = validateResult.fieldName
      if (field.labels) {
        label = field.labels[this.state.languageIndex].value;
      }
      alert(`${label} : ${validateResult.error}`);
      this.setState({formSubmitting: false});
    }
  }

  executeSubmit(captchaResponse){
    let that = this;
    that.removeIntervalCaching();
    
    let record = cleanRecord(this.state.record, this.props.form.fields);
    let repeatersRecord = cleanRepeatersRecord(this.state.repeatersRecord, this.props.form.fields);

    let strData = JSON.stringify({data: record, repeaters_data: repeatersRecord, current_user: this.props.currentUser});
    let formData = CreateFormData(strData, this.state.files);

    fetch(this.state.submissionUrl, {
      method: 'POST',
      headers: {
              'X-CSRF-Token': this.props.authenticity_token,
              'FT': this.state.survey.web_form_token
            },
      body: formData})
    .then(function(response) {
      if(response.status === 200){
          that.removeCaching();
          that.setState({
            formSubmitted: true,
            formSubmitting: false,
            record: initialRecord(that.state.form, that.state.currentUser, that.state.autofillParams, that.state.survey),
            languageIndex: 0,
          })
      }
      else if (response.status === 401){
        that.setState({
          submissionAuthFailed: true,
          formSubmitting:false
        }, that.addIntervalCaching)
      }
      else if (response.status === 500){
        that.setState({
          serverError: true,
          formSubmitting:false
        }, that.addIntervalCaching)
      }
      else{
        that.setState({
          serverError: true,
          formSubmitting:false
        }, that.addIntervalCaching);
      }
    })
  }

  validateForm(){
    let record = this.state.record;
    let fields = this.relevantFields(record, this.props.form.fields);
    for(let cnt1 = 0; cnt1 < fields.length; cnt1++){
      let field = fields[cnt1];
      if(!isVisible(field)) continue;

      if(field.type == "group" && field.is_repeater){
        let endIndex = getGroupEndIndex(fields, cnt1, field.name);
        let repeaterRecords = this.state.repeatersRecord[field.name];
        if (repeaterRecords){
          for(let rptCnt = 0; rptCnt < repeaterRecords.length; rptCnt++){
            let rptRecord = repeaterRecords[rptCnt];
            let mergerptParentRecord = {...record, ...rptRecord};

            let rptRelevantFields = this.relevantFields(mergerptParentRecord, fields).filter(e => field.name === e.group_name);
            for(let cnt2 = 0; cnt2 < rptRelevantFields.length; cnt2++){
              let rptField = rptRelevantFields[cnt2];
              if(["group","end_group"].includes(rptField.type) === false){
                let validateResult = this.validateFieldValue(rptField, rptRecord)
                if (validateResult !== true){
                  validateResult["fieldName"] = rptField.name;
                  validateResult["error"] = `Record ${rptCnt+1}: ${validateResult["error"]} `;
                  return validateResult;
                }
              }
            }
          }
        }
        cnt1 = endIndex;
        continue;
      }
 
      if(["group","end_group"].includes(field.type) === false){
        let validateResult = this.validateFieldValue(field, this.state.record)
        if (validateResult !== true){
          validateResult["fieldName"] = field.name;
          return validateResult;
        }
      }
    }
    return null;
  }

  relevantFields(record, fields){
    let relevantFields = []
    for(let cnt=0; cnt < fields.length; cnt++){
      let field = fields[cnt];
      if(!isRelevant(record, field)){
        if (field.type == "group"){
          cnt = getGroupEndIndex(fields, cnt, field.name);
        }
        continue;
      }

      if((field.type != "string") || (field.type == "string" && (field.name !== "meta_instance_id" || !field.readonly || !field.metadata)) ){
        relevantFields.push({...field})
      }

      
      if(field.type == "barcode"){
        relevantFields.push({...field});
      }

      if(field.type === "geopoint"){
        relevantFields.push({...field});
      }
    }
    return relevantFields;
  }

  validateFieldValue(field, record){
    let type = field.type;
    let name = field.name;
    let label = field.labels[this.state.languageIndex].value;
    let value = "";
    if(Object.keys(record).includes(field.name)){
      value = record[name]
    }
    let required = field.required;
    switch(type) {
      case "string":
      break;
      case "select":
      break;
      case "select1":
      break;
      case "int":
      break;
      case "decimal":
      break;
      case "dateTime":
      break;
      case "date":
      break;
      case "time":
      break;
      case "geopoint":
        if (Object.keys(record).includes(name.concat('_lat')) && record[name.concat('_lat')]){
          value = record[name.concat('_lat')].toString();
        }
      break;
      case "binary":
      break;
      case "barcode":
        let confirmationValue = "";
        if (Object.keys(record).includes(`${name}_confirmation`)) {
          let uncheckValue = record[`${name}_confirmation`]
          if ("undefined" !== typeof uncheckValue  && uncheckValue  !== null) {
            confirmationValue = uncheckValue
          }
        }
        if(confirmationValue !== value){
          return {error: `The values entered for the Barcode do not match. Please confirm the barcode number.`};
        }
      break;
    }
    // run this check for all field types
    if (required && isEmpty(value)) {
      return {error: `Required.`};
    }

    return true;
  }

  renderSurvey(){
    let listOfFields = []
    let fields = this.props.form.fields;
    let record = this.state.record;
    let grouping = false;
    let groupLabel = '';
    let isGroupVisible = false
    let label = "";

    if(this.props.form.languages.length > 1){
      let currentLanguage = this.props.form.languages[this.state.languageIndex]
      listOfFields.push(<Language languages={this.props.form.languages} setLanguage={this.setLanguage} currentLanguage={currentLanguage}/>)
    }

    for(let cnt=0; cnt < fields.length; cnt++){
      let field = fields[cnt];
      let key = `f_${cnt}`;
      if(!isVisible(field)) continue;

      if(!isRelevant(record, field)){
        if (field.type == "group"){
          cnt = getGroupEndIndex(fields, cnt, field.name);
        }
        continue;
      }

      if(field.type == "group" && !field.is_repeater){
        if (field.labels) {
          label = field.labels[this.state.languageIndex].value;
        }
        groupLabel = label;
        isGroupVisible = true

        listOfFields.push(
          <div key={`g${key}`}>
            <GroupLabel title={groupLabel} />
          </div>
        );
      }
      if(field.type== "end_group"){
        isGroupVisible = false;
      }

      if(field.type == "group" && field.is_repeater){
        let rRecords = this.state.repeatersRecord[field.name];
        if(!rRecords){ rRecords = [] }

        let repeaterRender = (<SinglePageRepeater key={key}
            repeaterField={field}
            files={this.state.files}
            fieldIndex={cnt}
            form={this.props.form}
            parentRecord={this.state.record}
            repeaterRecords={rRecords}
            getLocation={this.getLocation}
            gettingLocation={this.state.gettingLocation}
            languageIndex= {this.state.languageIndex}
            handleChange={this.handleChange}
            handleMultiSelectChange={this.handleMultiSelectChange}
            handleGroupedCheckboxes={this.handleGroupedCheckboxes}
            handleFileChange={this.handleFileChange}
            updateRepeaterRecords={this.updateRepeaterRecords}
            updateFiles={this.updateFiles}
        />);
        listOfFields.push(repeaterRender)
        cnt = getGroupEndIndex(fields, cnt, field.name);
      }
      else if(!["group", "end_group"].includes(field.type)) {
        listOfFields.push(
        <Field key={key}
          fieldItem={field}
          groupLabel={groupLabel}
          isGroupVisible={isGroupVisible}
          record={this.state.record}
          files={this.state.files}
          handleChange={this.handleChange}
          handleMultiSelectChange={this.handleMultiSelectChange}
          handleGroupedCheckboxes={this.handleGroupedCheckboxes}
          handleFileChange={this.handleFileChange}
          handleRemoveValue={this.handleRemoveValue}
          getLocation={this.getLocation}
          gettingLocation={this.state.gettingLocation}
          languageIndex= {this.state.languageIndex}
          />);
      }
    }

    return (
      <div className="web-form container">
        <form>
          {listOfFields}
          <div className="form-buttons">
            <button key="submit-button" type="submit" className="yo btn btn-default" disabled={this.state.formSubmitting} onClick={this.handleSubmit} >{this.state.formSubmitting ? <Spinner /> : "SUBMIT"}</button>
            <button key="print-button" type="submit" className="yo btn btn-default" disabled={this.state.formSubmitting} onClick={(e)=>{ e.preventDefault(); window.print(); return false;}}>PRINT PAGE</button>
          </div>
          {/* <ReCAPTCHA
            sitekey={this.state.captchaSiteKey}
            size="invisible"
            ref={recaptchaRef}
          /> */}
        </form>
      </div>
    );
  }

  refreshPage(){
    let languageIndex = 0;
    if(this.props.form.default_language)
    {
      languageIndex = this.props.form.languages.indexOf(this.props.form.default_language);
      languageIndex = languageIndex < 0 ? 0: languageIndex;
    }

    var defaultValue = {
      ...this.state,
      record: initialRecord(this.props.form, this.state.currentUser, this.state.autofillParams, this.state.survey),
      repeatersRecord: {},
      languageIndex: languageIndex,
      formSubmitting: false,
      formSubmitted: false,
      submissionAuthFailed: null,
      serverError: null,
      loaded: true,
      gettingLocation: false,
      loadFromCache: false 
    }
    this.setState(defaultValue, this.addCacheListener);
  }

  closePage(){
    window.postMessage("closeSurveyForm", window.location.origin);
  }

  render () {
    if(this.state.loaded){
      window.onbeforeunload = null;
      if(this.state.formSubmitted){
        return (
          <div className="web-form container">
            <h2>Thank you for filling out the form.</h2>
            <p>Your information has been collected successfully.</p>
          </div>
        )
      }
      else if (this.state.submissionAuthFailed){
        return (
          <div className="web-form container">
            <h2>You are not authorized to submit data.</h2>
          </div>
        )
      }
      else if (this.state.serverError){
        return (
          <div className="web-form container">
          <h2>Submission Failed</h2>
          <p>There has been an error on the server, and your data has not been saved. We apologize for the inconvienience.</p>
          </div>
        )
      }
      else if(this.state.loadFromCache){
        return (
          <div className="web-form container">
            <h2>Do you want to continue with the previous unsubmitted form?</h2>
            <div className="form-buttons">
              <button key="continue-button" type="button" className="yo btn btn-default" onClick={this.loadFromCache}>
                  Continue
              </button>
              <button key="next-button" type="button" className="yo btn btn-default" onClick={this.refreshPage}>
                  No
              </button>
            </div>
          </div>
        )
      }
      else{
        window.onbeforeunload = this.warnBeforeLeaving;
        return this.renderSurvey();
      }
    }
  }
}

export default SurveyForm
